#dev-logo {
    height: fit-content;
    max-width: 90%;
    stroke-width: 6;
}

#dev-logo path:nth-child(2) {
    stroke-dasharray: 440px;
    stroke-dashoffset: 440px;
    animation: line-anim 1.5s ease forwards;
}

#dev-logo path:nth-child(3) {
    stroke-dasharray: 386px;
    stroke-dashoffset: 386px;
    animation: line-anim 1.5s ease forwards;
}

#dev-logo path:nth-child(4) {
    stroke-dasharray: 281px;
    stroke-dashoffset: 281px;
    animation: line-anim 1.5s ease forwards;
}

#dev-logo path:nth-child(5) {
    stroke-dasharray: 280px;
    stroke-dashoffset: 280px;
    animation: line-anim 1.5s ease forwards;
}

#dev-logo path:nth-child(6) {
    stroke-dasharray: 568px;
    stroke-dashoffset: 568px;
    animation: line-anim 1.5s ease forwards;
}

#dev-logo path:nth-child(7) {
    stroke-dasharray: 451px;
    stroke-dashoffset: 451px;
    animation: line-anim 1.5s ease forwards;
}

#dev-logo path:nth-child(8) {
    stroke-dasharray: 219px;
    stroke-dashoffset: 219px;
    animation: line-anim 1.5s ease forwards;
}

#dev-logo path:nth-child(9) {
    stroke-dasharray: 459px;
    stroke-dashoffset: 459px;
    animation: line-anim 1.5s ease forwards;
}

#dev-logo path:nth-child(10) {
    stroke-dasharray: 413px;
    stroke-dashoffset: 413px;
    animation: line-anim 1.5s ease forwards;
}

#dev-logo path:nth-child(11) {
    stroke-dasharray: 376px;
    stroke-dashoffset: 376px;
    animation: line-anim 1.5s ease forwards;
}

#dev-logo path:nth-child(12) {
    stroke-dasharray: 617px;
    stroke-dashoffset: 617px;
    animation: line-anim 1.5s ease forwards;
}

#dev-logo path:nth-child(13) {
    stroke-dasharray: 214px;
    stroke-dashoffset: 214px;
    animation: line-anim 1.5s ease forwards;
}

#dev-logo path:nth-child(14) {
    stroke-dasharray: 376px;
    stroke-dashoffset: 376px;
    animation: line-anim 1.5s ease forwards;
}

#dev-logo path:nth-child(15) {
    stroke-dasharray: 459px;
    stroke-dashoffset: 459px;
    animation: line-anim 1.5s ease forwards;
}

#dev-logo path:nth-child(16) {
    stroke-dasharray: 459px;
    stroke-dashoffset: 459px;
    animation: line-anim 1.5s ease forwards;
}

#dev-logo path:nth-child(17) {
    stroke-dasharray: 219px;
    stroke-dashoffset: 219px;
    animation: line-anim 1.5s ease forwards;
}


@keyframes line-anim {
    to {
        stroke-dashoffset: 0;
    }
}

@media (max-width: 660px) {
    #dev-logo path:nth-child(2) {
        stroke-dasharray: 0;
        animation: none;
    }
    
    #dev-logo path:nth-child(3) {
        stroke-dasharray: 0;
        animation: none;
    }
    
    #dev-logo path:nth-child(4) {
        stroke-dasharray: 0;
        animation: none;
    }
    
    #dev-logo path:nth-child(5) {
        stroke-dasharray: 0;
        animation: none;
    }
    
    #dev-logo path:nth-child(6) {
        stroke-dasharray: 0;
        animation: none;
    }
    
    #dev-logo path:nth-child(7) {
        stroke-dasharray: 0;
        animation: none;
    }
    
    #dev-logo path:nth-child(8) {
        stroke-dasharray: 0;
        animation: none;
    }
    
    #dev-logo path:nth-child(9) {
        stroke-dasharray: 0;
        animation: none;
    }
    
    #dev-logo path:nth-child(10) {
        stroke-dasharray: 0;
        animation: none;
    }
    
    #dev-logo path:nth-child(11) {
        stroke-dasharray: 0;
        animation: none;
    }
    
    #dev-logo path:nth-child(12) {
        stroke-dasharray: 0;
        animation: none;
    }
    
    #dev-logo path:nth-child(13) {
        stroke-dasharray: 0;
        animation: none;
    }
    
    #dev-logo path:nth-child(14) {
        stroke-dasharray: 0;
        animation: none;
    }
    
    #dev-logo path:nth-child(15) {
        stroke-dasharray: 0;
        animation: none;
    }
    
    #dev-logo path:nth-child(16) {
        stroke-dasharray: 0;
        animation: none;
    }
    
    #dev-logo path:nth-child(17) {
        stroke-dasharray: 0;
        animation: none;
    }
    
}