@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* border: lightblue 1px solid; */
}

body {
    margin: 0;
    height: 100vh;
    font-family: "Raleway", serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));
}
