.about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #d3cccc;
}

.about-header {
    font-size: 60px;
    text-align: center;
    color: #d3cccc;
    margin-bottom: 1rem;
}

.ece-pic {
    width: 450px;
    max-width: 80vw;
}

.about-me {
    margin: 1rem 1rem;
    
    max-width: 70ch;
}

.about-me2 {
    margin: 1rem 1rem;
    max-width: 70ch;
}