.contact-link {
    display: flex;
    justify-content: center;
}

.contact-button {
    position: relative;
    font-size: 30px;
    color: #363cdf;
    margin: 1rem 0;
    padding: 0.25em 0.5em;
    border: transparent;
    background: transparent;
    transition: 0.5s;
}

.contact-button:hover {
    cursor: pointer;
}

.contact-button:before {
    content: "";
    background: linear-gradient(
        97deg,
        rgba(213, 93, 255, 1) 0%,
        #363cdf 45%,
        rgba(213, 93, 255, 1) 100%
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    border-radius: 10px;
}

.contact-button:hover:before {
    opacity: 1;
}

.contact-button:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 400% 0;
    }
    100% {
        background-position: 0 0;
    }
}
@media (max-width: 660px) {
    .contact-button {
        margin-top: 0;
    }
}
