.projects-section {
    margin: 0 1rem;
}

.skill-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #d3cccc;
}

.skill-list h1 {
    font-size: 60px;
}

.skill-icons, .tech-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.icon {
    text-align: center;
    margin: 1rem 1rem;
}

.skill-icon, .tech-icon {
    font-size: 5rem;
    color: #8536df;
}

.project-cards {
    display: flex;
    flex-direction: row;
}
