.home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #d3cccc;
}

.home-content p {
    font-size: 35px;
    margin-bottom: 0.5rem;
}

.my-name {
    max-width: 80%;
    font-size: 60px;
    text-align: center;
}

@media (max-width: 660px) {
    .home-content p {
        font-size: 20px;
    }

    .my-name {
        font-size: 45px;
    }
}
