.contact-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: auto;
    color: #d3cccc;
}
.contact-header {
    text-align: center;
    font-size: 60px;
    color: var(--text-primary);
    text-transform: uppercase;
    letter-spacing: 6px;
}

.my-pic {
    height: auto;
    margin-top: 0.5rem;
    max-height: auto;
    max-width: 200px;
    width: 25%;
    border-radius: 50%;
}

.contact-wrapper {
    display: flex;
    align-items: flex-end;
}

.direct-contact-container {
    text-align: center;
}

.contact-list {
    list-style-type: none;
    margin-right: 1rem;
}

.list-item {
    line-height: 4;
    color: var(--text-primary);
}

.contact-icon {
    margin-right: 0.5rem;
}

.contact-text {
    font: 24px "Lato", sans-serif;
    letter-spacing: 1.9px;
    color: #d3cccc;
}

.contact-text a {
    color: #363cdf;
    text-decoration: none;
    transition-duration: 0.2s;
}

.contact-text a:hover {
    color: #d3cccc;
    background-color: #8536df;
    text-decoration: none;
}

hr {
    margin-right: 1rem;
    color: #8536df;
}

@media (max-width: 825px) {
    .contact-wrapper {
        display: grid;
    }

    .contact-header {
        font-size: 40px;
    }

    .contact-list {
        text-align: center;
    }

    .contact-text {
        font-size: 20px;
    }

    hr {
        margin-right: 0;
        margin-bottom: 1rem;
    }
}

@media (max-width: 525px) {
    .contact-header {
        font-size: 35px;
    }
    .contact-text {
        letter-spacing: 0px;
    }
}
