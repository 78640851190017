.navbar {
    position: relative;
    background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));
    border: 1px solid #8536df;
    margin: 1rem 10%;
    height: 50px;
    z-index: 999 !important;
}

.nav-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-nav {
    display: flex;
    list-style: none;
    font-size: 1.5rem;
}

.social-link {
    font-size: 2rem;
    margin-top: 0.5rem;
    display: flex;
    justify-items: center;
}

.social-link .nav-text {
    margin: 0 1rem;
}

.menue-icon {
    font-size: 2rem;
    margin: 0 1rem;
    display: none;
}

.navbar-link .nav-text {
    margin: 0 0.5rem;
    padding: 0 0.5rem;
}

.nav-text {
    color: #363cdf;
    transition-duration: 0.2s;
}

.nav-text:hover {
    color: #d3cccc;
    background-color: #8536df;
    text-decoration: none;
}

@media (max-width: 720px) {
    .navbar {
        padding-right: 1rem;
    }

    .navbar-nav {
        font-size: 1.25rem;
    }

    .social-link {
        font-size: 1.75rem;
    }
}

@media (max-width: 660px) {
    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menue-icon {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
    }

    .hidden {
        display: flex;
        list-style: none;
        position: absolute;
        top: 50px;
        height: fit-content;
        width: 100%;
        border: 1px solid #8536df;
        background-image: linear-gradient(
            to left,
            rgba(27 20 41),
            rgba(20 15 35)
        );
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .hidden .navbar-nav {
        display: flex;
        flex-direction: column;
    }

    .hidden .navbar-link {
        margin: 0.5rem 0;
    }

    .hidden .nav-text {
        margin-bottom: 0.5rem;
    }

    .nav-links {
        display: none;
    }
}
