.card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card-items {
    display: inline-flex;
    flex: 0 0 33.3%;
    min-width: 30ch;
}

.card {
    border: 1px solid #8536df;
    margin: 0.5rem 1rem;
    color: #d3cccc;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card h2 {
    margin-top: 0.5rem;
    height: 60px;
    text-align: center;
}

.card img {
    max-height: auto;
    border-top: 1px solid #8536df ;
    max-width: 100%;
    margin-top: 0.5rem;
    vertical-align: middle;
}

.project-info {
    border: 1px solid #8536df;
    height: 150px;
    padding: 0.5rem;
    margin: 0px;

}

.project-links {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-around;
}

.project-svg {
    font-size: 2rem;
    color: #363cdf;
    transition-duration: 0.2s;
    padding: 0 0.5rem;
    padding-top: 0.5rem;
}

.project-svg:hover {
    color: #d3cccc;
    background-color: #8536df;
    text-decoration: none;
}
